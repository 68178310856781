<template>
    <div class="misc-inner p-2 p-sm-3">
        <div class="content-popover">
            <h2 class="mb-1">DateTimePicker</h2>
            <DatePickerFull
                v-model="date"
                :type="'date'"
                :startsWithTime="false"
                :format="format ? 24 : 12"
                :position="position"
                :showSeconds="showSeconds"
                :showDates="[new Date('01-01-2022'), new Date()]"
                :blockDates="(date) => date >= new Date()"
                :range="range"
            >
                <template v-slot:input>
                    <button class="picker-btn" type="button">
                        <div class="btn-icon">
                            <CalendarIcon />
                        </div>
                        <div class="btn-content">
                            <span>Data</span>
                            <p>
                                {{ formatDate(date) }}
                            </p>
                        </div>
                    </button>
                </template>
            </DatePickerFull>
            <div class="option">
                <input
                    type="checkbox"
                    v-model="showSeconds"
                    @click="() => (showSeconds = !showSeconds)"
                />
                Mostrar segundos<br />
            </div>
            <div class="option">
                <input
                    type="checkbox"
                    v-model="format"
                    @click="() => (format = !format)"
                />
                Formato 24h<br />
            </div>
            <div class="option">
                <input
                    type="checkbox"
                    v-model="disabled"
                    @click="() => (disabled = !disabled)"
                />
                Desabilitado<br />
            </div>
            <div class="option">
                <input
                    type="checkbox"
                    v-model="range"
                    @click="() => (range = !range)"
                />
                Habilitar range<br />
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import DatePickerFull from "../@core/components/date-time-picker/DatePickerFull.vue";
import CalendarIcon from "@/assets/images/icons/calendar-icon-2.svg";

export default {
    components: { DatePickerFull, CalendarIcon },
    data() {
        return {
            date: [
                new Date(new Date().setDate(new Date().getDate() - 7)),
                new Date(),
            ],
            showSeconds: true,
            format: true,
            type: "datetime",
            disabled: false,
            position: "bottom",
            range: true,
        };
    },
    mounted() {
        this.UPDATE_FLAG_SHOW_OVERLAY(false);
    },
    methods: {
        formatDate(data) {
            var dataFormatada = "";
            if (this.range && data) {
                if (data.length) {
                    var dia = String(data[0].getDate()).padStart(2, "0");
                    var mes = String(data[0].getMonth() + 1).padStart(2, "0");
                    var ano = data[0].getFullYear();
                    dataFormatada = dia + "/" + mes + "/" + ano;
                }
                if (data.length == 2) {
                    dia = String(data[1].getDate()).padStart(2, "0");
                    mes = String(data[1].getMonth() + 1).padStart(2, "0");
                    ano = data[1].getFullYear();
                    dataFormatada =
                        dataFormatada + " ~ " + dia + "/" + mes + "/" + ano;
                }
                return dataFormatada;
            } else if (data) {
                var dia = String(data.getDate()).padStart(2, "0");
                var mes = String(data.getMonth() + 1).padStart(2, "0");
                var ano = data.getFullYear();
                var horas = String(data.getHours()).padStart(2, "0");
                var minutos = String(data.getMinutes()).padStart(2, "0");
                var segundos = String(data.getSeconds()).padStart(2, "0");

                if (this.type == "datetime") {
                    dataFormatada =
                        dia +
                        "-" +
                        mes +
                        "-" +
                        ano +
                        " " +
                        horas +
                        ":" +
                        minutos;
                } else if (this.type == "time") {
                    dataFormatada = horas + ":" + minutos;
                } else {
                    dataFormatada = dia + "-" + mes + "-" + ano;
                }

                if (this.showSeconds) {
                    dataFormatada += ":" + segundos;
                }

                return dataFormatada;
            }
        },
        ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
    },
};
</script>

<style lang="scss">
.option {
    display: flex;
    gap: 10px;
}
.content-popover {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.picker-btn {
    padding: 0;
    width: 365px;
    height: 124px;
    display: flex;
    align-items: center;
    border: 1px solid #cfc4be;
    border-radius: 16px;
    background: #fafafa;
    box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
    overflow: hidden;
    .btn-icon {
        height: 100%;
        display: flex;
        align-items: center;
        border-right: 1px solid #cfc4be;
        padding: 10px 24px;
    }
    .btn-content {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-inline: 24px;
        font-size: 14px;
        font-weight: 400;
        gap: 4px;

        p {
            margin: 0;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
        }
    }
}
</style>
